
import { Vue, Component } from "vue-property-decorator";
import EbarTable from "@/components/ebar/EbarTable.vue";
import { VenueModel } from "@/api/generated";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { EbarTable } })
export default class EbarOverview extends Vue {}
